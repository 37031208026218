html,
body {
  margin: 0;
}

#App {font-family: sans-serif;height: 100vh;}
#page-wrap {text-align: center;overflow: auto;top: 45%;position: relative;}
.navbar {width: 100%;height: 50px;background: #000; position: fixed;z-index: 1000;}
.bm-item {display: inline-block; /* Our sidebar item styling */text-decoration: none;margin-bottom: 10px;color: #d1d1d1;transition: color 0.2s;}
.bm-item:hover {color: white;}
.bm-burger-button {position: fixed;width: 30px;height: 24px;right: 20px;top: 13px;}
.bm-burger-bars {background: #fff;height: 15% !important;}
.bm-cross-button {height: 24px;width: 24px;}
.bm-cross {background: #bdc3c7;}
.bm-menu {background: #333333;padding: 2.5em 1.5em 0 0;font-size: 1.15em;}
.bm-morph-shape {fill: #373a47;}
.bm-item-list {color: #b8b7ad;}
.bm-overlay {background: rgba(0, 0, 0, 0.3);}
.bm-menu-wrap {left: 0px !important;}

.bm-item.menu-item {background: #666666; margin-bottom: 2px; padding: 10px 0 10px 15px;}

.linkliste {margin-left: -15px;}
.linkliste li {list-style: none;}
.linkliste li a {background: #666666;margin-bottom: 2px;padding: 10px 0 10px 15px;width: 100%;display: block; color: #fff; text-decoration: none; font-size: 12px;}
.titel {margin: 15px;}

.schilddaten {line-height: 18px; font-size: 12px; margin-top: 10px;}
.schilddaten div {display: grid; grid-template-columns: 30% 70%; margin-bottom: 5px;}
.schildbildg {margin-top: 20px;}
.schildbild img {width: 100%;}

div canvas {width: 100%;}
.MuiInputBase-input {background: #efefef !important;}
.css-cveggr-MuiListItemIcon-root {color: #fff !important;}
.ulmargin {margin-left: -20px;}

.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.content {padding: 0 20px;}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
