*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
a {text-decoration: none;}
.content {/*width: 1000px;min-width: 700px;*/ margin: 50px auto; font-size: 14px; width: 100%;}
.content .wrp {width: 80%; margin: auto;}
.webcam-container {margin: 50px auto; width: 100%;}
.webcam {width: 100%;}
.homebuttons {display: grid; grid-template-columns: 1fr 1fr;}
.form-control {margin: 10px 0;}
.grid1 {display: grid;grid-template-columns: 20% 80%;color: #333333;margin-bottom: 20px;background: #efefef;border-radius: 5px;}
.grid1 div {padding: 10px;}


.MuiAccordionDetails-root {background: #f2f1f4 !important;}
.thumbnail {width: 200px;}

ul.liste{margin: 0px 10px 0px -10px; padding-bottom: 10px;}
ul.liste.nolistitem{list-style: none; margin-left: -40px;}

.zentriert {margin: auto;width: 50%;text-align: center;}

.grid2 {display: grid;grid-template-columns: 50% 50%;color: #333333;margin-bottom: 10px;}
.grid2.nogrid {display: block;color: #333333;grid-gap: 10px;margin-bottom: 10px;}
.grid2 div {background: #efefef;border-radius: 5px;}
.grid2.modal div {background: none;border-radius: 0px;}
.grid2 .react-datepicker-popper  {background: none;}
.grid2 .react-datepicker-popper div  {background: #fff;}
.grid2 .react-datepicker-popper div {padding: 0;}
.grid2 .image {padding: 10px;text-align: center;}
.grid2 .image img {width: 100%;border-radius: 5px;}
.grid2_child {display: grid;grid-template-columns: 40% 60%;color: #333333;grid-gap: 10px; padding: 20px 20px 10px 20px;}
.child {padding: 20px;}
.divtitel {font-weight: 600;}
.mb {margin-bottom: 10px;}
.mr {margin-right: 10px;}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {border-bottom-color: #fff !important;}
.gridzeile {padding: 10px;}
.gridzeile.mr {margin-right: 10px;}
.rot {color: #ff0000;}
.MuiBox-root {width: 98% !important;}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {font-size: 12px;}
.listbutton {margin-right: 5px !important; margin-bottom: 5px !important; padding: 5px !important;font-size: 12px !important;}


.toureninfo {position: absolute; right: 30px;}

.buttongrid {display: grid;grid-template-columns: 49% 49%;column-gap: 2%; margin-bottom: 10px;}

.gridtop {display: grid; grid-template-columns: 1fr 1fr;}
.griddialog {display: grid; grid-template-columns: 70% 30%;}
.gridtop div:nth-child(2) {padding: 20px 0; text-align: right;}
.griddialog div:nth-child(2) {text-align: right;}
.griddialog div:nth-child(2) Button:nth-child(1) {margin-right: 10px;}
.karte {height: 90%;}
.markertooltip {width: 30px; height: 30px; border-radius: 50%; background: #ffffff; text-align: center;}
.markertooltip_aufbau {width: 30px; height: 30px; border-radius: 50%; background: #358d51; color: #ffffff; text-align: center;}
.markertooltip_abbau {width: 30px; height: 30px; border-radius: 50%; background: #8e2710; color: #ffffff; text-align: center;}

.hide {display: none;}
.slotimage {max-width: 500px; margin: auto;}
.slotimage h2 {text-align: center;}
.slotimage img {width: 100%;}

button a {color: #fff;}
button.backbutton {margin: 20px 0;}
button.actionbutton {/*margin: 0 0 0 20px;*/ width: 100px; margin-left: 10px;}

.MuiButtonGroup-root a {/*margin-bottom: 10px;*/}



.liste li{margin: 0 0 5px 0;}
.tabelle {width: 100%; overflow: scroll;border-radius: 5px;}
table {background: #efefef;border-radius: 5px;color: #333333; width: 100%;}
tbody tr:nth-child(odd){background-color: #dbdcdf;}
thead th {padding: 10px;}
tbody td {padding: 10px; font-size: 12px;}
tbody td.umbruch {white-space: nowrap;}

tbody tr {cursor: pointer;height: 50px;}
tbody tr.auftragabgeschlossen,tbody td.auftragabgeschlossen {opacity: 0.5;}
tbody td.aufbau {background: #358d51; color: #fff; text-align: center;}
tbody td.abbau {background: #8e2710; color: #fff; text-align: center;}
tbody td.service {background: #458fd9; color: #fff; text-align: center;}
tbody td.service {background: #d9883d; color: #fff; text-align: center;}
.text-center {text-align: center;}


td.gruen svg {color: #358d51;}
td.grau svg {color: #767171;}
td button {border: 0;}

.kilometerstand .MuiFormControl-root {margin-left: 10px; margin-right: 10px;}
.kilometerstand .MuiFormControl-root input {padding: 7px 14px !important;}

.map-container {height: 100%;width: 100%;}
.buttonFloatR {float: right;}
h2 div.aufbau {background: #358d51; color: #fff; font-size: 12px; padding: 5px}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper,.MuiPaper-root {width: 100%;}

@media (max-width: 500px) {
  .gridtop {display: block;}
}
@media (max-width: 600px) {
  .griddialog {display: block;}
  .zentriert {margin: auto;width: 100%;}
}

@media (max-width: 768px) {
  .content {/*max-width: 100%;min-width: 700px;*/ font-size: 14px;}
}

.grid2_div_mb{margin-bottom: 0px;}
.grid2_div_mb.scrollheight{max-height: 500px; overflow: scroll;}
.grid2_div_mb .image.design img{width: 50%;}
.grid2_div_mb.mr{margin-right: 10px;}
@media (max-width: 680px) {
  .grid2, .grid1 {display: block;}
  .grid2_div_mb{margin-bottom: 10px;}
  .grid2_div_mb.mr{margin-right: 0px;}
  .gridzeile.mr {margin-right: 0px;margin-bottom: 10px;}
}

tr.highlight {background: #f39200 !important;}

.datumFieldHide, .datumShowHide{display: none;}

.MuiBox-root{height: 90%;}
input.MuiInputBase-input, .MuiInputBase-input{/*height: 0.4em !important;*/ background: none !important;}
fieldset.MuiOutlinedInput-notchedOutline {left: -10px !important;}
.css-13r4j3i .MuiTextField-root {width: 100% !important;}

.loginIcon {text-align: center; margin-top: 50px; margin-bottom: 50px;}
.loginIcon img { width: 80px;}

.userInfo,
._loginRegister{
  padding: 20px;
  max-width: 400px;
  margin: 10px auto;
  width: 100%;
}

._loginRegister h1{
  text-align: center;
  margin: 0 0 20px 0;
}

._loginRegister .form-control{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

._loginRegister .form-control label{
  font-weight: bold;
  margin-bottom: 3px;
}

[type="text"],
[type="date"],
[type="email"],
[type="password"]{
  padding:7px 10px;
  outline: none;
  margin-bottom: 5px;
  font-size: 16px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, .3);
  line-height: 28px;
}

[type="text"]:hover,
[type="text"]:focus,
[type="email"]:hover,
[type="email"]:focus,
[type="password"]:hover,
[type="password"]:focus
{
  border-color: #107a8b;
}

._navBtn button,
.form-control button,
[type="submit"]{
  background: #3295ff;
  color: #ffffff;
  border: 1px solid rgba(0, 0, 0, .1);
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 2px;
  margin-top: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  outline: none;
}
._navBtn button:hover,
[type="submit"]:hover{
  opacity: .9;
}

.form-control button:hover,
[type="button"]:hover{
  opacity: .9;
}

._navBtn{
  border-top: 1px solid #dedede;
  margin-top: 5px;
}
._navBtn button{
  background: #555555;
  color: #ffffff;
  width: 100%;

}

.success-msg,
.error-msg{
  border: 1px solid #ff304f;
  padding: 10px;
  color: #ff304f;
  font-weight: bold;
  border-radius: 2px;
}

.success-msg{
  color: #24b273;
  border-color: #24b273;
}

.hinweis {color: #24b273; }
.hinweisachtung {color: #8e9098; font-size: 12px;}

.userInfo{
  text-align: center;
}

.userInfo ._img{
  font-size: 50px;
}
.userInfo h1{
  text-transform: capitalize;
  margin: 10px 0;
}
.userInfo ._email{
  margin: 10px 0;
  color: #555555;
}
.userInfo button,.userInfo .button{
  margin-top: 5px;
  background: #3295ff;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 16px;
  border: 5px solid rgba(0, 0, 0, .1);
  outline: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  transition: background .3s;
}
.userInfo button:hover,.userInfo .button:hover{
  border-color: #3295ff;
  color: #3295ff;
  background: #ffffff;
}

.city {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
}

.wetterbg {
  background: url('bg.jpg') !important;
  background-size: cover !important;
  background-position: center !important;
}

p {
  margin-top: 10px;
  /*text-transform: uppercase;*/
  letter-spacing: 0.05em;
}

.city-temp {
  font-size: 5rem;
  font-weight: bold;
  margin-top: 10px;
  color: #1e2432;
  text-align: center;
}

.city sup {
  font-size: 0.5em;
}

.city-name {
  font-size: 2em;
}

.city-name sup {
  padding: 0.2em 0.6em;
  margin-left: 0.2em;
  border-radius: 30px;
  color: #fff;
  background: #ff8c00;
}

.city-icon {
  margin-top: 10px;
  width: 100px;
  height: 100px;
}
